import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Link from './Link'

const LinkStyled = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const onClick = (event, listPagePath) => {
  if (listPagePath) {
    // We must have navigated from a a list page, so just go back a page
    event.preventDefault()
    window.history.go(-1)
  }
}

const BackLink = ({ listPagePath }) => (
  <LinkStyled
    to={listPagePath || '/'}
    onClick={event => onClick(event, listPagePath)}
  >
    <h3>← Return to Product Directory</h3>
  </LinkStyled>
)

BackLink.propTypes = {
  listPagePath: PropTypes.string,
}

export default BackLink
