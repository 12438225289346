import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import ProductDetails from '../components/ProductDetails'
import ProductType from '@papertrailio/react-web-core/types/productType'

import isModalCheck from '../utils/isModalCheck'

const productTemplate = ({ pageContext, location }) => {
  const isModal =
    (location.state &&
      location.state.isModal &&
      isModalCheck(location.state.isModal)) ||
    false
  const listPagePath = (location.state && location.state.listPagePath) || ''

  if (pageContext.product) {
    return (
      <ProductDetails
        isModal={isModal}
        product={pageContext.product}
        listPagePath={listPagePath}
        isUniqueProduct={false}
        addToListAction={() => {
          // Dismiss modal
          isModal ? window.history.go(-1) : navigate('/import-list')
        }}
      />
    )
  }

  if (location.state && location.state.product) {
    return (
      <ProductDetails
        isModal={isModal}
        product={{
          ...location.state.product,
          ...(!location.state.product.productFields && {
            productFields: location.state.product.fields.data.map(field => ({
              // Add the arrayValue field
              ...field,
              valueArray: field.type === 'array' ? field.value : [],
              value: field.type === 'array' ? '' : field.value,
            })),
          }),
        }}
        listPagePath={listPagePath}
        isUniqueProduct={true}
        addToListAction={() => {
          // Dismiss modal
          isModal ? window.history.go(-1) : navigate('/import-list')
        }}
      />
    )
  }

  return null
}

productTemplate.propTypes = {
  pageContext: PropTypes.shape({
    product: ProductType,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export default productTemplate
