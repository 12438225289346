import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import ProductDetailsModal from '@papertrailio/react-web-core/components/product/ProductDetails'
import ProductDetailsPage from '../components/products/ProductDetailsPage'

const ProductDetails = ({ isModal, ...props }) => isModal ? (
    <Layout isModal modalBackgroundPath={props.listPagePath}>
      <ProductDetailsModal isModal={isModal} {...props} />
    </Layout>
  ) : (
    <Layout>
      <ProductDetailsPage isModal={isModal} {...props} />
    </Layout>
  )

ProductDetails.propTypes = {
  isModal: PropTypes.bool.isRequired,
}

export default ProductDetails
