import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ContentContainer from '@papertrailio/react-web-core/components/ContentContainer'
import BottomCTA from '@papertrailio/react-web-core/components/text/BottomCTA'
import Button from '@papertrailio/react-web-core/components/Button'
import Link from '@papertrailio/react-web-core/components/Link'
import ProductType from '@papertrailio/react-web-core/types/productType'
import ProductDetails from '@papertrailio/react-web-core/components/product/ProductDetails'

import BackLink from '../BackLink'

import Testimonial from '../Testimonial'
import Helmet from '../Helmet'

const ProductDetailsPage = ({ product, listPagePath, ...props }) => (
  <Fragment>
    <Helmet
      title={`${product.manufacturer} ${product.name}`}
      description={`View details of the ${product.manufacturer} ${product.name
        }, including photos and downloads. Easily import the product information into Papertrail.`}
    />

    <Fragment>
      <ContentContainer>
        <BackLink listPagePath={listPagePath} />

        <ProductDetails product={product} {...props} />
      </ContentContainer>

      <Testimonial />

      <BottomCTA>
        <Button
          theme="primaryRed"
          href="https://www.papertrail.io/book-demo?utm_source=product-directory&utm_campaign=book-demo"
          track={{
            category: 'demoLink',
            action: 'click',
            label: 'bottom',
          }}
          Link={Link}
        >
          Book a demo
        </Button>
      </BottomCTA>
    </Fragment>
  </Fragment>
)

ProductDetailsPage.propTypes = {
  product: ProductType,
  listPagePath: PropTypes.string.isRequired,
}

export default ProductDetailsPage
